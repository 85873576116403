/*====================================================
	New colours
====================================================*/
/*====================================================
	New colours
====================================================*/
body { background-color: #eee; }

.app_banner { background-color: #0072a3; background-color: rgba(0, 114, 163, 0.75); padding: 7px 15px; }

.alert { margin: 0 auto 30px; }

.white-copy { text-shadow: 1px 1px rgba(0, 0, 0, 0.65); }
.white-copy * { color: #fff; }

.form-signin { padding: 15px 30px; background-color: #fff; }
.form-signin legend { border: 0 none; margin-bottom: 0; padding-top: 0; }
.form-signin .form-signin-heading { color: #666666; margin: 10px 0; text-transform: uppercase; }
.form-signin .form-signin-heading strong { color: #0095D6; }
.form-signin .form-signin-heading .ppp-icon { color: #fff; background-color: #0095D6; padding: 0.15em; }
.form-signin .checkbox { font-weight: normal; margin-bottom: 10px; }
.form-signin .form-control { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; position: relative; height: auto; padding: 10px; font-size: 16px; }
.form-signin .form-control:focus { z-index: 2; }
.form-signin .help-links { padding-left: 0; font-size: 70%; }
.form-signin .help-links li { list-style: none; line-height: 2; }

/* Extra markup and styles for table-esque vertical and horizontal centering */
.site-wrapper { display: table; width: 100%; height: 100%; /* For at least Firefox */ padding-top: 150px; padding-bottom: 150px; background-color: #333333; background-image: url('../../../_images/PPP Log in page image.jpg?1662547436'); background-repeat: no-repeat; background-size: cover; background-position: center; }
.site-wrapper .site-wrapper-inner { display: table-cell; vertical-align: top; }
@media only screen and (min-width: 768px) { .site-wrapper .site-wrapper-inner { vertical-align: middle; } }

.cover-container { margin-right: auto; margin-left: auto; }
@media only screen and (min-width: 768px) { .cover-container { width: 100%; } }
@media only screen and (min-width: 992px) { .cover-container { width: 992px; } }
@media only screen and (min-width: 1500px) { .cover-container { width: 1500px; } }

/* Padding for spacing */
.inner { padding: 30px; }

/*
* Cover
*/
.cover { padding: 0; }
.cover .btn-lg { padding: 10px 20px; font-weight: 700; }
